/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotogalerie"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--left pt--80" name={"fotogalerie"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--1 pb--80 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: rgb(50, 69, 52);\"><br>Fotogalerie</span>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Ubytování je ve standartně vybavených dvou až sedmi lůžkových pokojích. <br>Větší pokoje disponují vlastním sociálním zařízením, ty menší pak společnými WC a sprchou.\n<br>V přízemí pensionu je společenská místnost-jídelna. "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--12 pt--12" name={"ucrb0ulpro"} style={{"backgroundColor":"#324534"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--white);\">Apartmán až 7 lůžek</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--16 pt--16" name={"c0eo2wf9w3o"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":650}} content={"Apartmán k dispozici s vlastní kuchyňkou"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"3vkb6py5s2"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/e5e45d70b16c448ab4e4f5c1688b8f78_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/33596/e5e45d70b16c448ab4e4f5c1688b8f78_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/e5e45d70b16c448ab4e4f5c1688b8f78_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/e5e45d70b16c448ab4e4f5c1688b8f78_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/e5e45d70b16c448ab4e4f5c1688b8f78_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/e672a0350daf490bb900faec3c631f47_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/33596/e672a0350daf490bb900faec3c631f47_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/e672a0350daf490bb900faec3c631f47_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/e672a0350daf490bb900faec3c631f47_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/e672a0350daf490bb900faec3c631f47_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/c63f83cd39374ee4855154b0ec9562ed_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/33596/c63f83cd39374ee4855154b0ec9562ed_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/c63f83cd39374ee4855154b0ec9562ed_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/c63f83cd39374ee4855154b0ec9562ed_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/c63f83cd39374ee4855154b0ec9562ed_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/eef2e59799db4c128dae0c7075c0783f_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/33596/eef2e59799db4c128dae0c7075c0783f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/eef2e59799db4c128dae0c7075c0783f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/eef2e59799db4c128dae0c7075c0783f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/eef2e59799db4c128dae0c7075c0783f_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/827b2c2ed2df45b8b1b75304f2c68759_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/33596/827b2c2ed2df45b8b1b75304f2c68759_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/827b2c2ed2df45b8b1b75304f2c68759_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/827b2c2ed2df45b8b1b75304f2c68759_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/827b2c2ed2df45b8b1b75304f2c68759_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/36bfaeb2c7cd46b09a8c371b7eb54de5_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/33596/36bfaeb2c7cd46b09a8c371b7eb54de5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/36bfaeb2c7cd46b09a8c371b7eb54de5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/36bfaeb2c7cd46b09a8c371b7eb54de5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/36bfaeb2c7cd46b09a8c371b7eb54de5_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--12 pt--12" name={"d2a3ag8l8j7"} style={{"backgroundColor":"#324534"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--white);\">Pokoj č.1</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"vrrtjscfv7a"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/a56fd99a066c4d19ad235cc0fa65ac08_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/a56fd99a066c4d19ad235cc0fa65ac08_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/a56fd99a066c4d19ad235cc0fa65ac08_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/a56fd99a066c4d19ad235cc0fa65ac08_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/a56fd99a066c4d19ad235cc0fa65ac08_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/55880dd574c84cae85ba2a978bd70420_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/55880dd574c84cae85ba2a978bd70420_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/55880dd574c84cae85ba2a978bd70420_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/55880dd574c84cae85ba2a978bd70420_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/55880dd574c84cae85ba2a978bd70420_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--12 pt--12" name={"cz45yduvcpr"} style={{"backgroundColor":"#324534"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--white);\"> Pokoj č.2</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"xi6szup7ges"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/30d1a2bf9a5f4a0f89491e8b81a6f421_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":539}} srcSet={"https://cdn.swbpg.com/t/33596/30d1a2bf9a5f4a0f89491e8b81a6f421_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/30d1a2bf9a5f4a0f89491e8b81a6f421_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/30d1a2bf9a5f4a0f89491e8b81a6f421_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/30d1a2bf9a5f4a0f89491e8b81a6f421_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="pl--10" src={"https://cdn.swbpg.com/t/33596/e6f18cea511248328fdb00c9ab16b304_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/e6f18cea511248328fdb00c9ab16b304_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/e6f18cea511248328fdb00c9ab16b304_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/e6f18cea511248328fdb00c9ab16b304_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/e6f18cea511248328fdb00c9ab16b304_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/33596/e6f18cea511248328fdb00c9ab16b304_s=2000x_.jpg 2000w"}>
              </Image>

              <Image className="pl--10" src={"https://cdn.swbpg.com/t/33596/2437ceaf30cf4878b2a923e7f24290c2_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":552}} srcSet={"https://cdn.swbpg.com/t/33596/2437ceaf30cf4878b2a923e7f24290c2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/2437ceaf30cf4878b2a923e7f24290c2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/2437ceaf30cf4878b2a923e7f24290c2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/2437ceaf30cf4878b2a923e7f24290c2_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--12 pt--12" name={"cz45yduvcpr"} style={{"backgroundColor":"#324534"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--white);\"> Pokoj č.3</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"mhxqde0vx0p"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/c5d0482c957f4ba28d68555c5b995319_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/c5d0482c957f4ba28d68555c5b995319_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/c5d0482c957f4ba28d68555c5b995319_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/c5d0482c957f4ba28d68555c5b995319_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/c5d0482c957f4ba28d68555c5b995319_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/33596/c5d0482c957f4ba28d68555c5b995319_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/873af3dc6c9446e890a3f72d0194b931_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/873af3dc6c9446e890a3f72d0194b931_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/873af3dc6c9446e890a3f72d0194b931_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/873af3dc6c9446e890a3f72d0194b931_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/873af3dc6c9446e890a3f72d0194b931_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--12 pt--12" name={"cz45yduvcpr"} style={{"backgroundColor":"#324534"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--white);\"> Pokoj č.4</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--16 pt--30" name={"jyz2kwhiv"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/dd1593b251ef415e8f24592bc5ab2fd3_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/dd1593b251ef415e8f24592bc5ab2fd3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/dd1593b251ef415e8f24592bc5ab2fd3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/dd1593b251ef415e8f24592bc5ab2fd3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/dd1593b251ef415e8f24592bc5ab2fd3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/33596/dd1593b251ef415e8f24592bc5ab2fd3_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/1a8d9f8264f8419ba920f38936a227a4_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/1a8d9f8264f8419ba920f38936a227a4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/1a8d9f8264f8419ba920f38936a227a4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/1a8d9f8264f8419ba920f38936a227a4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/1a8d9f8264f8419ba920f38936a227a4_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/33596/1a8d9f8264f8419ba920f38936a227a4_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--30 pt--12" name={"0scdozudwhzg"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/86801d366d7f4948acc441830fb0a3aa_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/86801d366d7f4948acc441830fb0a3aa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/86801d366d7f4948acc441830fb0a3aa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/86801d366d7f4948acc441830fb0a3aa_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/86801d366d7f4948acc441830fb0a3aa_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/eea600332ab042bcb2a0b4f3b69f741f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/eea600332ab042bcb2a0b4f3b69f741f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/eea600332ab042bcb2a0b4f3b69f741f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/eea600332ab042bcb2a0b4f3b69f741f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/eea600332ab042bcb2a0b4f3b69f741f_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--08" name={"3rcukoc1ssr"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--center pb--12 pt--12" name={"5k3sxiyilma"} style={{"backgroundColor":"#324534"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--white);\">Pokoj č.5&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--30 pt--25" name={"ti3l0rz6cji"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/9c73b200526043bebac78c87c310e526_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/9c73b200526043bebac78c87c310e526_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/9c73b200526043bebac78c87c310e526_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/9c73b200526043bebac78c87c310e526_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/9c73b200526043bebac78c87c310e526_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/39b2322547264cf2a1e24d8c2f1ef570_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/39b2322547264cf2a1e24d8c2f1ef570_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/39b2322547264cf2a1e24d8c2f1ef570_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/39b2322547264cf2a1e24d8c2f1ef570_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/39b2322547264cf2a1e24d8c2f1ef570_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--06 pt--0" name={"ivp72mxwj5q"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--center pb--12 pt--12" name={"6j8pkseuzxi"} style={{"backgroundColor":"#324534"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--white);\">Pokoj č. 6</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--30 pt--30" name={"3g8k7rz46rj"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/da1c6a2479384732ad0f9b3e84f71b25_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/da1c6a2479384732ad0f9b3e84f71b25_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/da1c6a2479384732ad0f9b3e84f71b25_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/da1c6a2479384732ad0f9b3e84f71b25_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/da1c6a2479384732ad0f9b3e84f71b25_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/33596/da1c6a2479384732ad0f9b3e84f71b25_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/eea600332ab042bcb2a0b4f3b69f741f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/eea600332ab042bcb2a0b4f3b69f741f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/eea600332ab042bcb2a0b4f3b69f741f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/eea600332ab042bcb2a0b4f3b69f741f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/eea600332ab042bcb2a0b4f3b69f741f_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--12 pt--12" name={"6j8pkseuzxi"} style={{"backgroundColor":"#324534"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--white);\">Společenská místnost - Jídelna</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--30 pt--40" name={"x6pixiohp9m"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/538950fe57f54295a3a66f0b8dd37efa_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/538950fe57f54295a3a66f0b8dd37efa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/538950fe57f54295a3a66f0b8dd37efa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/538950fe57f54295a3a66f0b8dd37efa_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/538950fe57f54295a3a66f0b8dd37efa_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/33596/538950fe57f54295a3a66f0b8dd37efa_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/33596/9fdc7c12dee74e44b3b062ba7bbd00a3_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/33596/9fdc7c12dee74e44b3b062ba7bbd00a3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/33596/9fdc7c12dee74e44b3b062ba7bbd00a3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/33596/9fdc7c12dee74e44b3b062ba7bbd00a3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/33596/9fdc7c12dee74e44b3b062ba7bbd00a3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/33596/9fdc7c12dee74e44b3b062ba7bbd00a3_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--08" name={"rgqvaaqary"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-m2oo0 pb--80 pt--80" name={"rezervace"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/33596/f8761de667d94e01bfd1617c1026c220_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pb--20 pt--20">
              
              <Title className="title-box fs--48" content={"<span style=\"color: var(--white);\">Zavolejte nebo napište e-mail</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: rgb(50, 69, 52);\">PENZION HANA STRÁŽNÉ</span><br>"}>
              </Title>

              <Text className="text-box fs--26" content={"Adresa: Strážné 138, 543 52 Strážné <br>Telefonní číslo: +420 777 307 212 <br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; +420 605 519 619<br>"}>
              </Text>

              <Text className="text-box" content={"Nikola Brothánková <br>IČO: 06187901\n<br>SÍDLO: Strážné <br>Registrován v živnostenském rejstříku."}>
              </Text>

              <Text className="text-box" content={"<a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box fs--22" content={"<span style=\"caret-color: rgb(50, 69, 52); color: rgb(50, 69, 52);\">pensionhanastrazne@seznam.cz</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":400}} content={"Nebojte se na nás obrátit s jakýmikoli dotazy."}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/33596/54749835bf1242db9636efb96e336ccb_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":158}} srcSet={"https://cdn.swbpg.com/t/33596/54749835bf1242db9636efb96e336ccb_s=350x_.png 350w, https://cdn.swbpg.com/t/33596/54749835bf1242db9636efb96e336ccb_s=660x_.png 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}